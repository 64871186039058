import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISetCookieConsentAction, ISetSiteConfigAction, ISiteConfig } from './types';

export type AdminState = { cookieConsent: boolean; siteConfig: ISiteConfig };

const initialState: AdminState = {
  cookieConsent: false,
  siteConfig: {},
};

const Admin = createSlice({
  name: 'Admin',
  initialState: initialState,
  reducers: {
    setCookieConsent: (state, action: PayloadAction<ISetCookieConsentAction>) => {
      state.cookieConsent = action.payload.cookieConsent;
    },
    setSiteConfig: (state, action: PayloadAction<ISetSiteConfigAction>) => {
      state.siteConfig = action.payload.siteConfig;
    },
  },
});

export const { setCookieConsent, setSiteConfig } = Admin.actions;
export default Admin.reducer;
