import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
  borderRadius,
  colorUsage,
  fontFamily,
  fontSize,
  fontWeight,
  getSpacing,
  lineHeight,
} from 'stylesheet';
import Loader from 'components/Loader';

export interface IProps {
  margin?: string;
  minHeight?: string;
  secondary?: boolean;
  loading?: boolean;
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = styled.button<IProps>`
  padding: ${getSpacing(2)} ${getSpacing(4)};

  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  border: none;
  border-radius: ${borderRadius.medium};

  text-decoration: none;

  color: ${(props) =>
    props.secondary ? colorUsage.secondaryButtonColor : colorUsage.primaryButtonColor};
  background-color: ${(props) =>
    props.disabled
      ? props.secondary
        ? colorUsage.secondaryButtonBackgroundDisabled
        : colorUsage.primaryButtonBackgroundDisabled
      : props.secondary
      ? colorUsage.secondaryButtonBackground
      : colorUsage.primaryButtonBackground};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.secondary
          ? colorUsage.secondaryButtonBackgroundDisabled
          : colorUsage.primaryButtonBackgroundDisabled
        : props.secondary
        ? colorUsage.secondaryButtonBackgroundHover
        : colorUsage.primaryButtonBackgroundHover};
  }

  ${(props) => props.margin && `margin: ${props.margin}`}
  ${(props) => props.minHeight && `min-height: ${props.minHeight}`}
`;

export const ButtonWithLoader: React.FC<IProps> = (props) => (
  <Button {...props}>{props.loading ? <Loader multiplier={0.4} /> : props.children}</Button>
);
export default Button;
