/*eslint max-lines: ["error", 1000]*/

import { RootState } from 'redux/types';

export const dummyState: RootState = {
  admin: {
    cookieConsent: false,
    siteConfig: {},
  },
  battingRows: {
    loading: false,
    byIds: {
      indians1: {
        id: 'indians1',
        scorecardId: 'scorecardTest',
      },
      indians2: {
        id: 'indians2',
        scorecardId: 'scorecardTest',
      },
      indians3: {
        id: 'indians4',
        scorecardId: 'scorecardTest',
      },
      indians4: {
        id: 'indians4',
        scorecardId: 'scorecardTest',
      },
      indians5: {
        id: 'indians5',
        scorecardId: 'scorecardTest',
      },
      indians6: {
        id: 'indians6',
        scorecardId: 'scorecardTest',
      },
      indians7: {
        id: 'indians7',
        scorecardId: 'scorecardTest',
      },
      indians8: {
        id: 'indians8',
        scorecardId: 'scorecardTest',
      },
      indians9: {
        id: 'indians9',
        scorecardId: 'scorecardTest',
      },
      cubs1: {
        id: 'cubs1',
        scorecardId: 'scorecardTest',
        1: {
          plateAppearanceId: 'fowler1',
        },
        3: {
          plateAppearanceId: 'fowler2',
        },
        5: {
          plateAppearanceId: 'fowler3',
        },
        7: {
          plateAppearanceId: 'fowler4',
        },
        9: {
          plateAppearanceId: 'fowler5',
        },
      },
      cubs2: {
        id: 'cubs2',
        scorecardId: 'scorecardTest',
        1: {
          plateAppearanceId: 'schwarber1',
        },
        3: {
          plateAppearanceId: 'schwarber2',
        },
        5: {
          plateAppearanceId: 'schwarber3',
        },
        7: {
          plateAppearanceId: 'schwarber4',
        },
        10: {
          plateAppearanceId: 'schwarber5',
        },
      },
      cubs3: {
        id: 'cubs3',
        scorecardId: 'scorecardTest',
        1: {
          plateAppearanceId: 'bryant1',
        },
        4: {
          plateAppearanceId: 'bryant2',
        },
        5: {
          plateAppearanceId: 'bryant3',
        },
        7: {
          plateAppearanceId: 'bryant4',
        },
        10: {
          plateAppearanceId: 'bryant5',
        },
      },
      cubs4: {
        id: 'cubs4',
        scorecardId: 'scorecardTest',
        1: {
          plateAppearanceId: 'rizzo1',
        },
        4: {
          plateAppearanceId: 'rizzo2',
        },
        5: {
          plateAppearanceId: 'rizzo3',
        },
        8: {
          plateAppearanceId: 'rizzo4',
        },
        10: {
          plateAppearanceId: 'rizzo5',
        },
      },
      cubs5: {
        id: 'cubs5',
        scorecardId: 'scorecardTest',
        1: {
          plateAppearanceId: 'zobrist1',
        },
        4: {
          plateAppearanceId: 'zobrist2',
        },
        5: {
          plateAppearanceId: 'zobrist3',
        },
        8: {
          plateAppearanceId: 'zobrist4',
        },
        10: {
          plateAppearanceId: 'zobrist5',
        },
      },
      cubs6: {
        id: 'cubs6',
        scorecardId: 'scorecardTest',
        2: {
          plateAppearanceId: 'russell1',
        },
        4: {
          plateAppearanceId: 'russell2',
        },
        6: {
          plateAppearanceId: 'russell3',
        },
        8: {
          plateAppearanceId: 'russell4',
        },
        10: {
          plateAppearanceId: 'russell5',
        },
      },
      cubs7: {
        id: 'cubs7',
        scorecardId: 'scorecardTest',
        2: {
          plateAppearanceId: 'contreras1',
        },
        4: {
          plateAppearanceId: 'contreras2',
        },
        6: {
          plateAppearanceId: 'ross1',
        },
        9: {
          plateAppearanceId: 'ross2',
        },
        10: {
          plateAppearanceId: 'montero1',
        },
      },
      cubs8: {
        id: 'cubs8',
        scorecardId: 'scorecardTest',
        2: {
          plateAppearanceId: 'heyward1',
        },
        4: {
          plateAppearanceId: 'heyward2',
        },
        6: {
          plateAppearanceId: 'heyward3',
        },
        9: {
          plateAppearanceId: 'heyward4',
        },
        10: {
          plateAppearanceId: 'heyward5',
        },
      },
      cubs9: {
        id: 'cubs9',
        scorecardId: 'scorecardTest',
        3: {
          plateAppearanceId: 'baez1',
        },
        5: {
          plateAppearanceId: 'baez2',
        },
        6: {
          plateAppearanceId: 'baez3',
        },
        9: {
          plateAppearanceId: 'baez4',
        },
        10: {
          plateAppearanceId: 'baez5',
        },
      },
    },
    allIds: [
      'indians1',
      'indians2',
      'indians3',
      'indians4',
      'indians5',
      'indians6',
      'indians7',
      'indians8',
      'indians9',
      'cubs1',
      'cubs2',
      'cubs3',
      'cubs4',
      'cubs5',
      'cubs6',
      'cubs7',
      'cubs8',
      'cubs9',
    ],
  },
  lineups: {
    loading: false,
    byIds: {
      cubsWS: {
        id: 'cubsWS',
        scorecardId: 'scorecardTest',
        name: 'Cubs',
        1: { playerIds: ['fowler'], battingRowId: 'cubs1' },
        2: { playerIds: ['schwarber'], battingRowId: 'cubs2' },
        3: { playerIds: ['bryant'], battingRowId: 'cubs3' },
        4: { playerIds: ['rizzo'], battingRowId: 'cubs4' },
        5: { playerIds: ['zobrist'], battingRowId: 'cubs5' },
        6: { playerIds: ['russell'], battingRowId: 'cubs6' },
        7: { playerIds: ['contreras'], battingRowId: 'cubs7' },
        8: { playerIds: ['heyward'], battingRowId: 'cubs8' },
        9: { playerIds: ['baez'], battingRowId: 'cubs9' },
      },
      indiansWS: {
        id: 'indiansWS',
        scorecardId: 'scorecardTest',
        name: 'Indians',
        1: { playerIds: ['santana'], battingRowId: 'indians1' },
        2: { playerIds: ['kipnis'], battingRowId: 'indians2' },
        3: { playerIds: ['lindor'], battingRowId: 'indians3' },
        4: { playerIds: ['napoli'], battingRowId: 'indians4' },
        5: { playerIds: ['ramirez'], battingRowId: 'indians5' },
        6: { playerIds: ['chisenhall'], battingRowId: 'indians6' },
        7: { playerIds: ['davis'], battingRowId: 'indians7' },
        8: { playerIds: ['crisp'], battingRowId: 'indians8' },
        9: { playerIds: ['perez'], battingRowId: 'indians9' },
      },
    },
    allIds: ['cubsWS', 'indiansWS'],
  },
  plateAppearances: {
    loading: false,
    byIds: {
      fowler1: {
        id: 'fowler1',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 4,
        balls: 2,
        strikes: 1,
        info: 'HR',
      },
      fowler2: {
        id: 'fowler2',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 0,
        balls: 0,
        strikes: 0,
        info: 'L3',
      },
      fowler3: {
        id: 'fowler3',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 1,
        balls: 1,
        strikes: 2,
        info: '1B',
      },
      fowler4: {
        id: 'fowler4',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 1,
        balls: 0,
        strikes: 1,
        info: '1B',
      },
      fowler5: {
        id: 'fowler5',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 0,
        balls: 2,
        strikes: 0,
        info: '6-3',
      },
      schwarber1: {
        id: 'schwarber1',
        scorecardId: 'scorecardTest',
        playerId: 'schwarber',
        baseReached: 2,
        balls: 1,
        strikes: 2,
        info: '1B',
      },
      schwarber2: {
        id: 'schwarber2',
        scorecardId: 'scorecardTest',
        playerId: 'schwarber',
        baseReached: 1,
        balls: 1,
        strikes: 0,
        info: '1B',
      },
      schwarber3: {
        id: 'schwarber3',
        scorecardId: 'scorecardTest',
        playerId: 'schwarber',
        baseReached: 0,
        balls: 0,
        strikes: 0,
        info: '6-4-3',
      },
      schwarber4: {
        id: 'schwarber4',
        scorecardId: 'scorecardTest',
        playerId: 'schwarber',
        baseReached: 0,
        balls: 1,
        strikes: 2,
        info: 'L7',
      },
      schwarber5: {
        id: 'schwarber5',
        scorecardId: 'scorecardTest',
        playerId: 'schwarber',
        baseReached: 4,
        balls: 0,
        strikes: 0,
        info: '1B',
      },
      bryant1: {
        id: 'bryant1',
        scorecardId: 'scorecardTest',
        playerId: 'bryant',
        baseReached: 0,
        balls: 3,
        strikes: 2,
        info: 'F9',
      },
      bryant2: {
        id: 'bryant2',
        scorecardId: 'scorecardTest',
        playerId: 'bryant',
        baseReached: 4,
        balls: 3,
        strikes: 2,
        info: '1B',
      },
      bryant3: {
        id: 'bryant3',
        scorecardId: 'scorecardTest',
        playerId: 'bryant',
        baseReached: 4,
        balls: 3,
        strikes: 2,
        info: 'BB',
      },
      bryant4: {
        id: 'bryant4',
        scorecardId: 'scorecardTest',
        playerId: 'bryant',
        baseReached: 0,
        balls: 2,
        strikes: 2,
        info: 'K',
      },
      bryant5: {
        id: 'bryant5',
        scorecardId: 'scorecardTest',
        playerId: 'bryant',
        baseReached: 0,
        balls: 2,
        strikes: 2,
        info: 'F8',
      },
      rizzo1: {
        id: 'rizzo1',
        scorecardId: 'scorecardTest',
        playerId: 'fowler',
        baseReached: 0,
        balls: 1,
        strikes: 0,
        info: 'F8',
      },
      rizzo2: {
        id: 'rizzo2',
        scorecardId: 'scorecardTest',
        playerId: 'rizzo',
        baseReached: 1,
        balls: 0,
        strikes: 2,
        info: 'HBP',
      },
      rizzo3: {
        id: 'rizzo3',
        scorecardId: 'scorecardTest',
        playerId: 'rizzo',
        baseReached: 2,
        balls: 1,
        strikes: 2,
        info: '1B',
      },
      rizzo4: {
        id: 'rizzo4',
        scorecardId: 'scorecardTest',
        playerId: 'rizzo',
        baseReached: 0,
        balls: 0,
        strikes: 2,
        info: 'K',
      },
      rizzo5: {
        id: 'rizzo5',
        scorecardId: 'scorecardTest',
        playerId: 'rizzo',
        baseReached: 4,
        balls: 3,
        strikes: 0,
        info: 'IBB',
      },
      zobrist1: {
        id: 'zobrist1',
        scorecardId: 'scorecardTest',
        playerId: 'zobrist',
        baseReached: 0,
        balls: 1,
        strikes: 1,
        info: 'F9',
      },
      zobrist2: {
        id: 'zobrist2',
        scorecardId: 'scorecardTest',
        playerId: 'zobrist',
        baseReached: 4,
        balls: 0,
        strikes: 0,
        info: 'FC',
      },
      zobrist3: {
        id: 'zobrist3',
        scorecardId: 'scorecardTest',
        playerId: 'zobrist',
        baseReached: 0,
        balls: 0,
        strikes: 1,
        info: 'L8',
      },
      zobrist4: {
        id: 'zobrist4',
        scorecardId: 'scorecardTest',
        playerId: 'zobrist',
        baseReached: 0,
        balls: 1,
        strikes: 2,
        info: '3',
      },
      zobrist5: {
        id: 'zobrist5',
        scorecardId: 'scorecardTest',
        playerId: 'zobrist',
        baseReached: 3,
        balls: 1,
        strikes: 2,
        info: '2B',
      },
      russell1: {
        id: 'russell1',
        scorecardId: 'scorecardTest',
        playerId: 'russell',
        baseReached: 0,
        balls: 2,
        strikes: 1,
        info: 'P6',
      },
      russell2: {
        id: 'russell2',
        scorecardId: 'scorecardTest',
        playerId: 'russell',
        baseReached: 0,
        balls: 0,
        strikes: 0,
        info: 'SF',
      },
      russell3: {
        id: 'russell3',
        scorecardId: 'scorecardTest',
        playerId: 'russell',
        baseReached: 0,
        balls: 2,
        strikes: 2,
        info: 'FO3',
      },
      russell4: {
        id: 'russell4',
        scorecardId: 'scorecardTest',
        playerId: 'russell',
        baseReached: 0,
        balls: 0,
        strikes: 2,
        info: 'PO3',
      },
      russell5: {
        id: 'russell5',
        scorecardId: 'scorecardTest',
        playerId: 'russell',
        baseReached: 2,
        balls: 3,
        strikes: 0,
        info: 'IBB',
      },
      contreras1: {
        id: 'contreras1',
        scorecardId: 'scorecardTest',
        playerId: 'contreras',
        baseReached: 0,
        balls: 0,
        strikes: 1,
        info: 'FO9',
      },
      contreras2: {
        id: 'contreras2',
        scorecardId: 'scorecardTest',
        playerId: 'contreras',
        baseReached: 2,
        balls: 2,
        strikes: 2,
        info: '2B',
      },
      ross1: {
        id: 'ross1',
        scorecardId: 'scorecardTest',
        playerId: 'ross',
        baseReached: 4,
        balls: 1,
        strikes: 2,
        info: 'HR',
      },
      ross2: {
        id: 'ross2',
        scorecardId: 'scorecardTest',
        playerId: 'ross',
        baseReached: 1,
        balls: 3,
        strikes: 1,
        info: 'BB',
      },
      montero1: {
        id: 'montero1',
        scorecardId: 'scorecardTest',
        playerId: 'montero',
        baseReached: 1,
        balls: 1,
        strikes: 1,
        info: '1B',
      },
      heyward1: {
        id: 'heyward1',
        scorecardId: 'scorecardTest',
        playerId: 'heyward',
        baseReached: 0,
        balls: 0,
        strikes: 0,
        info: 'P6',
      },
      heyward2: {
        id: 'heyward2',
        scorecardId: 'scorecardTest',
        playerId: 'heyward',
        baseReached: 0,
        balls: 3,
        strikes: 2,
        info: 'P6',
      },
      heyward3: {
        id: 'heyward3',
        scorecardId: 'scorecardTest',
        playerId: 'heyward',
        baseReached: 0,
        balls: 1,
        strikes: 0,
        info: '3',
      },
      heyward4: {
        id: 'heyward4',
        scorecardId: 'scorecardTest',
        playerId: 'heyward',
        baseReached: 3,
        balls: 2,
        strikes: 2,
        info: 'FC',
      },
      heyward5: {
        id: 'heyward5',
        scorecardId: 'scorecardTest',
        playerId: 'heyward',
        baseReached: 0,
        balls: 0,
        strikes: 2,
        info: 'K',
      },
      baez1: {
        id: 'baez1',
        scorecardId: 'scorecardTest',
        playerId: 'baez',
        baseReached: 0,
        balls: 1,
        strikes: 1,
        info: 'F7',
      },
      baez2: {
        id: 'baez2',
        scorecardId: 'scorecardTest',
        playerId: 'baez',
        baseReached: 4,
        balls: 0,
        strikes: 0,
        info: 'HR',
      },
      baez3: {
        id: 'baez3',
        scorecardId: 'scorecardTest',
        playerId: 'baez',
        baseReached: 0,
        balls: 0,
        strikes: 2,
        info: 'K',
      },
      baez4: {
        id: 'baez4',
        scorecardId: 'scorecardTest',
        playerId: 'baez',
        baseReached: 0,
        balls: 3,
        strikes: 2,
        info: 'K',
      },
      baez5: {
        id: 'baez5',
        scorecardId: 'scorecardTest',
        playerId: 'baez',
        baseReached: 0,
        balls: 2,
        strikes: 2,
        info: 'F8',
      },
    },
    allIds: [
      'fowler1',
      'fowler2',
      'fowler3',
      'fowler4',
      'fowler5',
      'schwarber1',
      'schwarber2',
      'schwarber3',
      'schwarber4',
      'schwarber5',
      'bryant1',
      'bryant2',
      'bryant3',
      'bryant4',
      'bryant5',
      'rizzo1',
      'rizzo2',
      'rizzo3',
      'rizzo4',
      'rizzo5',
      'zobrist1',
      'zobrist2',
      'zobrist3',
      'zobrist4',
      'zobrist5',
      'russell1',
      'russell2',
      'russell3',
      'russell4',
      'russell5',
      'contreras1',
      'contreras2',
      'ross1',
      'ross2',
      'montero1',
      'heyward1',
      'heyward2',
      'heyward3',
      'heyward4',
      'heyward5',
      'baez1',
      'baez2',
      'baez3',
      'baez4',
      'baez5',
    ],
  },
  players: {
    loading: false,
    byIds: {
      rob: {
        id: 'rob',
        scorecardId: 'scorecardTest',
        name: 'Rob Cronin',
        battingAverage: 348,
        position: 'SS',
      },
      fowler: {
        id: 'fowler',
        scorecardId: 'scorecardTest',
        name: 'Dexter Fowler',
        battingAverage: 250,
        position: 'CF',
      },
      schwarber: {
        id: 'schwarber',
        scorecardId: 'scorecardTest',
        name: 'Kyle Schwarber',
        battingAverage: 412,
        position: 'DH',
      },
      bryant: {
        id: 'bryant',
        scorecardId: 'scorecardTest',
        name: 'Kris Bryant',
        battingAverage: 308,
        position: '3B',
      },
      rizzo: {
        id: 'rizzo',
        scorecardId: 'scorecardTest',
        name: 'Anthony Rizzo',
        battingAverage: 277,
        position: '1B',
      },
      zobrist: {
        id: 'zobrist',
        scorecardId: 'scorecardTest',
        name: 'Ben Zobrist',
        battingAverage: 250,
        position: 'LF',
      },
      russell: {
        id: 'russell',
        scorecardId: 'scorecardTest',
        name: 'Addison Russell',
        battingAverage: 203,
        position: 'SS',
      },
      contreras: {
        id: 'contreras',
        scorecardId: 'scorecardTest',
        name: 'Willson Contreras',
        battingAverage: 256,
        position: 'C',
      },
      heyward: {
        id: 'heyward',
        scorecardId: 'scorecardTest',
        name: 'Jason Heyward',
        battingAverage: 104,
        position: 'RF',
      },
      baez: {
        id: 'baez',
        scorecardId: 'scorecardTest',
        name: 'Javier Baez',
        battingAverage: 265,
        position: 'LF',
      },
      hendricks: {
        id: 'hendricks',
        scorecardId: 'scorecardTest',
        name: 'Kyle Hendricks',
        battingAverage: 138,
        position: 'P',
      },
      santana: {
        id: 'santana',
        scorecardId: 'scorecardTest',
        name: 'Carlos Santana',
        battingAverage: 192,
        position: 'DH',
      },
      kipnis: {
        id: 'kipnis',
        scorecardId: 'scorecardTest',
        name: 'Jason Kipnis',
        battingAverage: 230,
        position: '2B',
      },
      lindor: {
        id: 'lindor',
        scorecardId: 'scorecardTest',
        name: 'Francisco Lindor',
        battingAverage: 310,
        position: 'SS',
      },
      napoli: {
        id: 'napoli',
        scorecardId: 'scorecardTest',
        name: 'Mike Napoli',
        battingAverage: 173,
        position: '1B',
      },
      ramirez: {
        id: 'ramirez',
        scorecardId: 'scorecardTest',
        name: 'Jose Ramirez',
        battingAverage: 268,
        position: '3B',
      },
      chisenhall: {
        id: 'chisenhall',
        scorecardId: 'scorecardTest',
        name: 'Lonnie Chisenhall',
        battingAverage: 214,
        position: 'RF',
      },
      davis: {
        id: 'davis',
        scorecardId: 'scorecardTest',
        name: 'Rajai Davis',
        battingAverage: 147,
        position: 'CF',
      },
      crisp: {
        id: 'crisp',
        scorecardId: 'scorecardTest',
        name: 'Coco Crisp',
        battingAverage: 269,
        position: 'LF',
      },
      perez: {
        id: 'perez',
        scorecardId: 'scorecardTest',
        name: 'Roberto Perez',
        battingAverage: 186,
        position: 'C',
      },
    },
    allIds: [
      'rob',
      'fowler',
      'schwarber',
      'bryant',
      'rizzo',
      'zobrist',
      'russell',
      'contreras',
      'heyward',
      'baez',
      'hendricks',
      'santana',
      'kipnis',
      'lindor',
      'napoli',
      'ramirez',
      'chisenhall',
      'davis',
      'crisp',
      'perez',
    ],
  },
  scorecards: {
    loading: false,
    byIds: {
      scorecardTest: {
        id: 'scorecardTest',
        name: 'Cubs @ Indians 2016 World Series Game 7',
        homeLineupId: 'indiansWS',
        awayLineupId: 'cubsWS',
        scorecardId: 'scorecardTest',
      },
    },
    allIds: ['scorecardTest'],
  },
};
