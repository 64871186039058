import styled from 'styled-components';
import { fontSize, getSpacing, fontWeight, fontFamily, lineHeight } from 'stylesheet';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${getSpacing(30)};
  padding: 0 ${getSpacing(4)};
  font-size: ${fontSize.large};
`;
HeaderContainer.displayName = 'HeaderContainer';

export const MainTitle = styled.h1`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XXLarge};
  line-height: ${lineHeight.medium};
  margin: auto;
  cursor: pointer;
`;
MainTitle.displayName = 'MainTitle';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
