import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPlateAppearance,
  ICreatePlateAppearanceAction,
  IUpdatePlateAppearanceAction,
} from './types';
import { NormalisedRedux } from 'redux/types';
import { dummyState } from '../dummyData';

export type PlateAppearancesState = NormalisedRedux<IPlateAppearance>;

// const initialState: PlateAppearancesState = {
//   loading: false,
//   byIds: {},
//   allIds: [],
// };

const PlateAppearances = createSlice({
  name: 'PlateAppearances',
  initialState: dummyState.plateAppearances,
  reducers: {
    createPlateAppearance: (state, action: PayloadAction<ICreatePlateAppearanceAction>) => {
      if (!state.byIds[action.payload.newPlateAppearanceId]) {
        state.byIds[action.payload.newPlateAppearanceId] = {
          id: action.payload.newPlateAppearanceId,
          scorecardId: action.payload.scorecardId,
          baseReached: 0,
          balls: 0,
          strikes: 0,
          info: '',
        };
        state.allIds.push(action.payload.newPlateAppearanceId);
      }
    },
    updatePlateAppearance: (state, action: PayloadAction<IUpdatePlateAppearanceAction>) => {
      const { id, ...updatedPlateAppearance } = action.payload;
      if (state.byIds[id]) {
        state.byIds[id] = {
          ...state.byIds[id],
          ...updatedPlateAppearance,
        };
      }
    },
  },
});

export const { createPlateAppearance, updatePlateAppearance } = PlateAppearances.actions;
export default PlateAppearances.reducer;
