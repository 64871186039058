import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'routes';
import { MainTitle, HeaderContainer, ButtonContainer } from './Header.style';
import { ExternalButton } from 'components/Button';
import { getSpacing } from 'stylesheet';

const Header: React.FC = () => {
  const history = useHistory();

  return (
    <HeaderContainer>
      <MainTitle onClick={() => history.push(PATHS.HOME)}>ScoreBall</MainTitle>
      <ButtonContainer>
        <ExternalButton margin={getSpacing(3)} href="https://patreon.com/scoreball">
          Support
        </ExternalButton>
        <ExternalButton
          margin={getSpacing(3)}
          href="https://docs.google.com/forms/d/e/1FAIpQLScG-SwZrq59zZh3D1UyHkKmrF9_Gv-yPd2nWWcOIYEyYIyq4Q/viewform"
        >
          Feedback
        </ExternalButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default Header;
