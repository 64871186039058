import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { client } from 'services/networking/client';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { useDispatch } from 'react-redux';

import { ISiteConfig } from 'redux/Admin/types';
import { setSiteConfig } from 'redux/Admin';

export const useFetchSiteConfig = (): AsyncFnReturn<() => Promise<undefined>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<undefined>(async () => {
    try {
      const siteConfig: ISiteConfig = await client.get('/get-site-config');
      dispatch(setSiteConfig({ siteConfig }));
    } catch (e) {
      return {
        error: 'Failed to fetch site config',
      };
    }
  }, []);
};
