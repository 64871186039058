import React, { ReactNode } from 'react';
import Button, { IProps } from './Button';
type IExternalProps = IProps & {
  href: string;
  children: ReactNode;
};
const ExternalButton: React.FunctionComponent<IExternalProps> = (props) => (
  <Button onClick={() => window.open(props.href, 'tab', 'noopener')} {...props}>
    {props.children}
  </Button>
);

export default ExternalButton;
