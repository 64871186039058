import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import ReactGA from 'react-ga';
import Loader from './components/Loader/Loader';

const Home = lazy(() => import('./pages/Home'));
const Scorecard = lazy(() => import('./pages/Scorecard'));

export const PATHS = {
  HOME: '/',
  SCORECARD: (scorecardId: string): string => `/scorecard/${scorecardId}`,
};

const routes: React.FunctionComponent = () => (
  <Suspense fallback={<Loader multiplier={1} />}>
    <Route
      path="/"
      render={({ location }) => {
        ReactGA.pageview(location.pathname + location.search);
        return null;
      }}
    />
    <Switch>
      <Route exact path={PATHS.HOME} component={Home} />
      <Route exact path={PATHS.SCORECARD(':scorecardId')} component={Scorecard} />
    </Switch>
  </Suspense>
);

export default routes;
