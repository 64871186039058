import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { AdminState } from './slice';

export const getAdminState = (store: RootState): AdminState => store.admin;
export const getCookieConsent = createSelector(getAdminState, (admin) => admin.cookieConsent);
export const getSiteConfig = createSelector(getAdminState, (admin) => admin.siteConfig);

export const getPublicUserId = createSelector(
  getSiteConfig,
  (siteConfig) => siteConfig.publicUserId,
);
