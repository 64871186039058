import { createSlice } from '@reduxjs/toolkit';
import { NormalisedRedux } from 'redux/types';
import { createPlateAppearance } from 'redux/PlateAppearances';
import { dummyState } from '../dummyData';
import { IBattingRow } from './types';
import { createScorecard } from 'redux/Scorecards';

export type BattingRowsState = NormalisedRedux<IBattingRow>;

// const initialState: BattingRowsState = {
//   loading: false,
//   byIds: {},
//   allIds: [],
// };

const BattingRows = createSlice({
  name: 'BattingRows',
  initialState: dummyState.battingRows,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPlateAppearance, (state, action) => {
      if (state.byIds[action.payload.battingRowId]) {
        state.byIds[action.payload.battingRowId][action.payload.inningNumber] = {
          plateAppearanceId: action.payload.newPlateAppearanceId,
        };
      }
    });
    builder.addCase(createScorecard, (state, action) => {
      const battingIds = [...action.payload.homeBattingRowIds, ...action.payload.awayBattingRowIds];
      battingIds.forEach((battingRowId) => {
        if (!state.byIds[battingRowId]) {
          state.byIds[battingRowId] = {
            id: battingRowId,
            scorecardId: action.payload.scorecardId,
          };
        }
      });
      state.allIds.push(...battingIds);
    });
  },
});

export default BattingRows.reducer;
