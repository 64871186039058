/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';

import { reducer as admin } from './Admin';
import { reducer as battingRows } from './BattingRows';
import { reducer as lineups } from './Lineups';
import { reducer as plateAppearances } from './PlateAppearances';
import { reducer as players } from './Players';
import { reducer as scorecards } from './Scorecards';
import { RootState } from './types';

export default combineReducers<RootState>({
  admin,
  battingRows,
  lineups,
  plateAppearances,
  players,
  scorecards,
});
