import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { Standard } from 'components/Typography';
import Button from 'components/Button';
import 'react-toastify/dist/ReactToastify.css';
import { IGACookieConsentProps } from './GACookieConsent.wrap';

type ICookieAcceptButtonProps = {
  setCookieConsent: (cookieConsent: boolean) => void;
  closeToast?: () => void;
};

const CookieAcceptButton: React.FunctionComponent<ICookieAcceptButtonProps> = (props) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Button
      minHeight="60%"
      onClick={() => {
        props.closeToast && props.closeToast();
        props.setCookieConsent(true);
      }}
    >
      Accept
    </Button>
  </div>
);

const GACookieConsent: React.FunctionComponent<IGACookieConsentProps> = (props) => {
  useEffect(() => {
    toast(
      <Standard>
        We use cookies for analytic tracking. Please click here to accept these cookies.
      </Standard>,
    );
  }, [props.setCookieConsent]);
  useEffect(() => {
    if (props.cookieConsent) {
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }, [props.cookieConsent]);
  return (
    <ToastContainer
      position={toast.POSITION.TOP_RIGHT}
      autoClose={false}
      closeButton={<CookieAcceptButton setCookieConsent={props.setCookieConsent} />}
      closeOnClick={false}
      draggable={false}
      style={{ minWidth: '30%' }}
    />
  );
};

export default GACookieConsent;
