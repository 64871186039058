import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlayer } from 'redux/Players/types';
import { NormalisedRedux } from 'redux/types';
import { ICreatePlayerAction, IUpdatePlayerAction } from './types';
import { dummyState } from '../dummyData';

export type PlayersState = NormalisedRedux<IPlayer>;

// const initialState: PlayersState = {
//   loading: false,
//   byIds: {},
//   allIds: [],
// };

const players = createSlice({
  name: 'Players',
  initialState: dummyState.players,
  reducers: {
    updatePlayer: (state, action: PayloadAction<IUpdatePlayerAction>) => {
      const { id, ...updatedPlayer } = action.payload;
      if (state.byIds[id]) {
        state.byIds[id] = {
          ...state.byIds[id],
          ...updatedPlayer,
        };
      }
    },
    createPlayer: (state, action: PayloadAction<ICreatePlayerAction>) => {
      const { id } = action.payload;
      if (!state.byIds[id]) {
        state.byIds[id] = {
          ...action.payload,
        };
        state.allIds.push(id);
      }
    },
  },
});

export const { updatePlayer, createPlayer } = players.actions;
export default players.reducer;
