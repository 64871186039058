import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IScorecard, ICreateScorecardAction } from './types';
import { NormalisedRedux } from 'redux/types';
import { dummyState } from '../dummyData';

export type ScorecardsState = NormalisedRedux<IScorecard> &
  Readonly<{ selectedScorecardId?: string }>;

export const initialState: ScorecardsState = {
  loading: false,
  byIds: {},
  allIds: [],
};

const Scorecards = createSlice({
  name: 'Scorecards',
  initialState: dummyState.scorecards,
  reducers: {
    setSelectedScorecard: (state, action: PayloadAction<IId>) => {
      state.selectedScorecardId = action.payload;
    },
    createScorecard: (state, action: PayloadAction<ICreateScorecardAction>) => {
      if (!state.byIds[action.payload.scorecardId]) {
        state.byIds[action.payload.scorecardId] = {
          id: action.payload.scorecardId,
          name: action.payload.name,
          homeLineupId: action.payload.homeLineupId,
          awayLineupId: action.payload.awayLineupId,
          scorecardId: action.payload.scorecardId,
        };
        state.allIds.push(action.payload.scorecardId);
      }
    },
  },
});

export const { createScorecard, setSelectedScorecard } = Scorecards.actions;
export default Scorecards.reducer;
