import React from 'react';
import { LoaderContainer, LoaderWrapper } from './Loader.style';

// Loader from https://loading.io/css/

interface IProps {
  multiplier: number;
}

const Loader: React.FunctionComponent<IProps> = (props) => (
  <LoaderWrapper>
    <LoaderContainer multiplier={props.multiplier}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoaderContainer>
  </LoaderWrapper>
);

export default Loader;
