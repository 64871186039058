import styled, { keyframes } from 'styled-components';
import { colorUsage } from 'stylesheet';

const loaderAnimation = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface ILoaderContainerProps {
  multiplier: number;
}
// Loader from https://loading.io/css/
/* stylelint-disable selector-max-type, selector-max-specificity */
export const LoaderContainer = styled.div<ILoaderContainerProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => `${props.multiplier * 64}px`};
  height: ${(props) => `${props.multiplier * 64}px`};

  div {
    position: absolute;
    width: ${(props) => `${props.multiplier * 5}px`};
    height: ${(props) => `${props.multiplier * 5}px`};
    background: ${colorUsage.loaderColorDefault};
    border-radius: 50%;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
  div:nth-child(1) {
    animation-delay: 0s;
    top: ${(props) => `${props.multiplier * 29}px`};
    left: ${(props) => `${props.multiplier * 53}px`};
  }
  div:nth-child(2) {
    animation-delay: -0.1s;
    top: ${(props) => `${props.multiplier * 18}px`};
    left: ${(props) => `${props.multiplier * 50}px`};
  }
  div:nth-child(3) {
    animation-delay: -0.2s;
    top: ${(props) => `${props.multiplier * 9}px`};
    left: ${(props) => `${props.multiplier * 41}px`};
  }
  div:nth-child(4) {
    animation-delay: -0.3s;
    top: ${(props) => `${props.multiplier * 6}px`};
    left: ${(props) => `${props.multiplier * 29}px`};
  }
  div:nth-child(5) {
    animation-delay: -0.4s;
    top: ${(props) => `${props.multiplier * 9}px`};
    left: ${(props) => `${props.multiplier * 18}px`};
  }
  div:nth-child(6) {
    animation-delay: -0.5s;
    top: ${(props) => `${props.multiplier * 18}px`};
    left: ${(props) => `${props.multiplier * 9}px`};
  }
  div:nth-child(7) {
    animation-delay: -0.6s;
    top: ${(props) => `${props.multiplier * 29}px`};
    left: ${(props) => `${props.multiplier * 6}px`};
  }
  div:nth-child(8) {
    animation-delay: -0.7s;
    top: ${(props) => `${props.multiplier * 41}px`};
    left: ${(props) => `${props.multiplier * 9}px`};
  }
  div:nth-child(9) {
    animation-delay: -0.8s;
    top: ${(props) => `${props.multiplier * 50}px`};
    left: ${(props) => `${props.multiplier * 18}px`};
  }
  div:nth-child(10) {
    animation-delay: -0.9s;
    top: ${(props) => `${props.multiplier * 53}px`};
    left: ${(props) => `${props.multiplier * 29}px`};
  }
  div:nth-child(11) {
    animation-delay: -1s;
    top: ${(props) => `${props.multiplier * 50}px`};
    left: ${(props) => `${props.multiplier * 41}px`};
  }
  div:nth-child(12) {
    animation-delay: -1.1s;
    top: ${(props) => `${props.multiplier * 41}px`};
    left: ${(props) => `${props.multiplier * 50}px`};
  }
`;
