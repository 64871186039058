import { createSlice } from '@reduxjs/toolkit';
import { NormalisedRedux } from 'redux/types';
import { createScorecard } from 'redux/Scorecards';
import { createPlayer } from 'redux/Players';
import { ILineup } from './types';
import { dummyState } from '../dummyData';

export type LineupsState = NormalisedRedux<ILineup>;

// const initialState: LineupsState = {
//   loading: false,
//   byIds: {},
//   allIds: [],
// };

const Lineups = createSlice({
  name: 'Lineups',
  initialState: dummyState.lineups,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createScorecard, (state, action) => {
      if (!state.byIds[action.payload.homeLineupId]) {
        state.byIds[action.payload.homeLineupId] = {
          id: action.payload.homeLineupId,
          scorecardId: action.payload.scorecardId,
          name: action.payload.homeLineupName,
          ...action.payload.homeBattingRowIds.reduce(
            (acc, battingRowId, index) => ({
              ...acc,
              [index + 1]: { playerIds: [], battingRowId },
            }),
            {},
          ),
        };
        state.allIds.push(action.payload.homeLineupId);
      }
      if (!state.byIds[action.payload.awayLineupId]) {
        state.byIds[action.payload.awayLineupId] = {
          id: action.payload.awayLineupId,
          scorecardId: action.payload.scorecardId,
          name: action.payload.awayLineupName,
          ...action.payload.awayBattingRowIds.reduce(
            (acc, battingRowId, index) => ({
              ...acc,
              [index + 1]: { playerIds: [], battingRowId },
            }),
            {},
          ),
        };
        state.allIds.push(action.payload.awayLineupId);
      }
    });
    builder.addCase(createPlayer, (state, action) => {
      if (state.byIds[action.payload.lineupId][action.payload.battingOrder]) {
        state.byIds[action.payload.lineupId][action.payload.battingOrder].playerIds.push(
          action.payload.id,
        );
      }
    });
  },
});

export default Lineups.reducer;
