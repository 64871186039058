import { fontFamily, fontSize, fontWeight, getSpacing, lineHeight, colorUsage } from 'stylesheet';
import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.large};
  line-height: ${lineHeight.medium};
  margin-bottom: ${getSpacing(4)};
`;
Title.displayName = 'Title';

export const SubTitle = styled.h2`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  margin-bottom: ${getSpacing(4)};
`;
SubTitle.displayName = 'SubTitle';

export const Standard = styled.p`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
`;
Standard.displayName = 'Standard';

export const StandardInput = styled.input`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  background-color: unset;
  width: 90%;
`;
StandardInput.displayName = 'StandardInput';

export const Text = styled.p`
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XSmall};
  line-height: ${lineHeight.small};
  height: ${lineHeight.small};
  color: ${colorUsage.primaryTextColor};
  text-align: center;
`;

export const ErrorText = styled(Text)`
  color: ${colorUsage.error};
`;
