import React, { ReactNode, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import Header from 'components/Header';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

import { PageContent, RootContainer } from './Root.style';
import GACookieConsent from './GACookieConsent';
import { useFetchSiteConfig } from 'redux/Admin';

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

interface Props {
  children: ReactNode;
}

const Root: React.FunctionComponent<Props> = (props) => {
  const [, doFetchSiteConfig] = useFetchSiteConfig();
  useEffect(() => {
    doFetchSiteConfig();
  }, [doFetchSiteConfig]);

  return (
    <IntlProvider locale="fr" messages={locales.fr}>
      <RootContainer>
        <GACookieConsent />
        <Header />
        <PageContent>{props.children}</PageContent>
      </RootContainer>
    </IntlProvider>
  );
};

export default Root;
