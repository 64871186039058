import { RootState } from 'redux/types';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { setCookieConsent } from 'redux/Admin';
import { getCookieConsent } from 'redux/Admin/selectors';
import GACookieConsent from './GACookieConsent';

const mapStateToProps = (state: RootState) => ({
  cookieConsent: getCookieConsent(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCookieConsent: (cookieConsent: boolean) => dispatch(setCookieConsent({ cookieConsent })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type IGACookieConsentProps = ConnectedProps<typeof connector>;
export default connector(GACookieConsent);
