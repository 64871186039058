/*eslint max-lines: ["error", 120]*/
/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
const colorPalette = {
  amberLight: '#FFD54F',
  amber: '#FFC107',
  amberDark: '#FF8F00',
  black: '#000000',
  blackTransparent: 'rgba(0, 0, 0, 0.24)',
  blueLight: '#F4F9FB',
  blue: '#74C3E3',
  blueDark: '#3FA2CA',
  gold: '#FFCC00',
  greenLight: '#00CC66',
  greyLight: '#E0E0E0',
  greyMedium: '#E9E9E9',
  greyDark: '#222222',
  red: '#FF7373',
  white: '#FFFFFF',
  yellow: '#E3CF74',
  yellowLight: '#E6DA96',
};

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  headerBackground: colorPalette.greyDark,
  primaryTextColor: colorPalette.greyDark,
  primaryLight: colorPalette.amberLight,
  primary: colorPalette.amber,
  primaryDark: colorPalette.amberDark,
  contentBackground: colorPalette.blueLight,
  linkColor: colorPalette.greyDark,
  linkColorHover: colorPalette.amberDark,
  linkColorDisabled: colorPalette.greyLight,
  codeColor: colorPalette.amberDark,
  primaryButtonColor: colorPalette.white,
  primaryButtonBackground: colorPalette.blueDark,
  primaryButtonBackgroundHover: colorPalette.blue,
  primaryButtonBackgroundDisabled: colorPalette.greyLight,
  secondaryButtonColor: colorPalette.white,
  secondaryButtonBackground: colorPalette.yellow,
  secondaryButtonBackgroundHover: colorPalette.yellowLight,
  secondaryButtonBackgroundDisabled: colorPalette.greyLight,
  loaderColorDefault: colorPalette.amberDark,
  error: colorPalette.red,
  inputBackground: colorPalette.white,
  inputBorderColor: colorPalette.blackTransparent,
  inputPlaceholderColor: colorPalette.blackTransparent,
  emptyBase: colorPalette.white,
  reachedBase: colorPalette.gold,
  baseballField: colorPalette.greenLight,
  disabledBase: colorPalette.greyLight,
  disabledBaseballField: colorPalette.greyMedium,
  diamondInfoBox: colorPalette.white,
  ballColor: colorPalette.greenLight,
  noBall: colorPalette.white,
  strikeColor: colorPalette.red,
  noStrike: colorPalette.white,
  modalBorder: colorPalette.black,
};

export const fontFamily = {
  main: `'Lato', 'Helvetica', 'Arial', sans-serif`,
  code: 'Monospace',
};

export const fontSize = {
  XXLarge: '60px',
  large: '24px',
  medium: '16px',
  small: '14px',
  XSmall: '12px',
};

export const fontWeight = {
  bold: '700',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
  small: '12px',
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const zIndices = {
  low: 1,
  medium: 2,
  high: 3,
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;
